import React, { useEffect, useState, useRef } from "react";
import { Box, Input, Text } from "theme-ui";
import { useController } from "react-hook-form";

import Frame from "components/Fields/Frame";
import MenuDialog from "components/MenuDialog";
import SelectItem from "components/Fields/Select/SelectItem";

import { useClickOutside } from "utils";

const SelectField = ({
  control,
  name,
  rules,
  options,
  icon,
  menuTitle,
  sx,
  withHeader = false,
  focusOnCurrent = false,
  className,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const scrollRef = useRef(null);

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const contianerRef = useClickOutside(closeDialog);

  const { field } = useController({
    name,
    control,
    rules,
  });

  useEffect(() => {
    if (!open || !scrollRef.current || !focusOnCurrent) return;

    const compareTo = field.value?.value ?? "07:00";

    const currentItem = options.findIndex((item) => item.value === compareTo);

    if (currentItem === -1) return;

    const top =
      scrollRef.current.children[currentItem].offsetTop -
      scrollRef.current.children[currentItem].offsetHeight * 2;

    scrollRef.current.scrollTo({
      top,
      behavior: "instant",
    });
  }, [open]);

  return (
    <Box
      sx={{
        position: "relative",
        ...sx,
      }}
      onFocus={openDialog}
      onClick={openDialog}
      ref={contianerRef}
      className={className}
    >
      <Frame {...props} name={name}>
        <Box sx={{ variant: "forms.input", display: "flex" }}>
          {field.value && (
            <Text
              sx={{
                display: "inline-flex",
                justifyContent: "center",
                color: "primary",
                fontSize: 6,
                mr: 2,
              }}
            >
              {icon}
            </Text>
          )}
          {field.value?.label}
        </Box>
        <Input
          {...field}
          id={name}
          type="hidden"
          className={field.value ? "withValue" : ""}
        />
      </Frame>
      {open && (
        <MenuDialog
          title={withHeader ? menuTitle : null}
          onClickDelete={() => {
            field.onChange(null);
          }}
          onClickClose={(e) => {
            e.stopPropagation();
            closeDialog();
          }}
        >
          <Box
            sx={{
              maxHeight: 64,
              overflow: "auto",
              gridColumn: "span 3",
            }}
            ref={scrollRef}
            onClick={(e) => e.stopPropagation()}
          >
            {options.map((item) => (
              <SelectItem
                key={`select-${item.value}`}
                onChange={() => {
                  field.onChange(item);
                  closeDialog();
                }}
                label={item.label}
              />
            ))}
          </Box>
        </MenuDialog>
      )}
    </Box>
  );
};

export default SelectField;
