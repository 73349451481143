import React from "react";
import { Box } from "theme-ui";

const SelectItem = ({ label, onChange }) => {
  return (
    <Box
      variant="body1"
      sx={{
        cursor: "pointer",
        py: 2,
        pl: 4,
        "&:hover": {
          background: "primary.selected",
        },
      }}
      onClick={onChange}
    >
      {label}
    </Box>
  );
};

export default SelectItem;
