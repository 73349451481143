import React, { useContext, useState } from "react";
import { Button, Box, Flex, Heading } from "theme-ui";

import ProfileLayout from "profile/ProfileLayout";
import Link from "components/Link";
import TextField from "components/Fields/Text";
import SelectField from "components/Fields/Select";
import LoadingIndicator from "components/LoadingIndicator";

import { useForm } from "react-hook-form";
import { AuthContext } from "Authentication/AuthProvider";
import axpAxios from "utils/axpAxios";
import SEO from "components/SEO";

const TITLE_OPTIONS = [
  {
    value: "Mr",
    label: "Mr.",
  },
  {
    value: "Mrs",
    label: "Mrs.",
  },
];

const Edit = () => {
  const { profile, refreshProfile } = useContext(AuthContext);

  const { register, control, handleSubmit } = useForm({
    defaultValues: {
      first_name: profile?.firstName,
      middle_name: profile?.middleName,
      last_name: profile?.lastName,
      title: TITLE_OPTIONS.find((option) => option.value === profile?.title),
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const personalInformationNameSubmit = async (data) => {
    setIsLoading(true);
    axpAxios
      .put("/guest/v1/current_profile", data)
      .then((response) => {
        alert("Name updated successfully");
        refreshProfile();
      })
      .catch((error) => {
        alert("Oops, something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <ProfileLayout hideBackArrow>
      {isLoading && <LoadingIndicator sx={{ height: "100vh" }} />}
      <Heading as="h6" variant="heading6" sx={{ ml: [4, 0] }}>
        Edit name
      </Heading>
      <Box sx={{ height: "fit-content", marginTop: 6 }}>
        <Box
          as="form"
          sx={{ bg: "componentBG1", borderRadius: 1, position: "relative" }}
          onSubmit={handleSubmit(personalInformationNameSubmit)}
        >
          <Box sx={{ p: 4 }}>
            <SelectField
              label="Title"
              control={control}
              name="title"
              options={TITLE_OPTIONS}
              labelBackground="componentBG1"
            />
          </Box>
          <Box sx={{ p: 4 }}>
            <TextField
              label="First name"
              register={register("first_name", { required: true })}
              labelBackground="componentBG1"
              styles={{
                display: "flex",
                alignItems: "center",
              }}
            />
          </Box>
          {/* <Box sx={{ p: 4 }}>
            <TextInput
              label="Middle name(s)"
              register={register("middle_name")}
              labelBackground="componentBG1"
              styles={{
                display: "flex",
                alignItems: "center",
              }}
            />
          </Box> */}
          <Box sx={{ p: 4 }}>
            <TextField
              label="Last name"
              register={register("last_name", { required: true })}
              labelBackground="componentBG1"
              styles={{
                display: "flex",
                alignItems: "center",
              }}
            />
          </Box>
          <Flex
            sx={{
              alignItems: "center",
              gap: 4,
              justifyContent: "flex-end",
              p: 4,
            }}
          >
            <Link variant="links.text" to="/profile">
              Cancel
            </Link>
            <Button type="submit">Save changes</Button>
          </Flex>
        </Box>
      </Box>
    </ProfileLayout>
  );
};

export default Edit;

export const Head = () => <SEO title="Edit profile name" />;
