import React from "react";

import { Divider, Flex, Grid, IconButton, Text } from "theme-ui";
import CloseOutlined from "@material-ui/icons/CloseOutlined";
import DeleteOutline from "@material-ui/icons/DeleteOutline";

const MenuDialog = ({ onClickClose, onClickDelete, title, children }) => {
  return (
    <Grid
      sx={{
        position: "absolute",
        top: "110%",
        left: 0,
        zIndex: "menu",

        gridTemplateColumns: "1rem auto 1rem",
        bg: "componentBG2",
        width: 85,
        maxWidth: "90vw",
        gap: 2,
        py: 4,
        boxShadow: "elevation3dp",
        "& > *": {
          gridColumn: "2",
        },
        "& > .fullSize": {
          gridColumn: "1 / span 3",
        },
      }}
    >
      {title && (
        <>
          <Flex
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {onClickDelete && (
              <IconButton
                variant="icon.light"
                type="button"
                sx={{ width: 6, height: 6 }}
                onClick={onClickDelete}
              >
                <DeleteOutline />
              </IconButton>
            )}
            <Text variant="subtitle1">{title}</Text>
            {onClickClose && (
              <IconButton
                variant="icon.light"
                type="button"
                sx={{ width: 6, height: 6 }}
                onClick={onClickClose}
              >
                <CloseOutlined />
              </IconButton>
            )}
          </Flex>
          <Divider className="fullSize" />
        </>
      )}

      {children}
    </Grid>
  );
};

export default MenuDialog;
